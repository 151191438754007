import { ProductsDocument } from '@/graphql/Products';
import { toNonNullable } from '@/utils/collections';
import { resolveProductPrice } from '@/utils/products';
import { Ref, ref, watch } from '@vue/composition-api';
import { ProductInterface, SearchResultPageInfo } from 'graphql-types.gen';
import { MaybeReactive, useQuery } from 'villus';

export type MappedProduct = ReturnType<typeof mapProduct>;

export function useProducts(variables?: MaybeReactive<any>) {
  const products: Ref = ref([]);
  const totalCount: Ref<number> = ref(0);
  const pageInfo: Ref<SearchResultPageInfo> = ref({});

  const { data, isFetching, execute, unwatchVariables } = useQuery({
    query: ProductsDocument,
    variables,
    cachePolicy: 'network-only',
  });

  unwatchVariables();

  watch(data, value => {
    products.value = toNonNullable(value?.products?.nodes as ProductInterface[])?.map(mapProduct);
    totalCount.value = value?.products?.total_count as number;
    pageInfo.value = value?.products?.page_info as SearchResultPageInfo;
  });

  return {
    products,
    totalCount,
    pageInfo,
    isFetchingProducts: isFetching,
    fetchProducts: execute,
  };
}

// Map product
function mapProduct(apiProduct: ProductInterface) {
  const currentPrice = resolveProductPrice(apiProduct);
  const oldPrice = apiProduct.price_range.maximum_price?.regular_price.value ?? currentPrice;
  const apiPrice = resolveProductPrice(apiProduct);
  const apiOldPrice = apiProduct.price_range.maximum_price?.regular_price.value ?? currentPrice;

  return {
    ...apiProduct,
    averageDuration: apiProduct?.average_duration || 0,
    price: resolveProductPrice(apiProduct),
    priceBefore: currentPrice < oldPrice ? oldPrice : undefined,
    discountPercentage: currentPrice / oldPrice !== 1 ? Math.round(((oldPrice - currentPrice) / oldPrice) * 100) : 0,
    apiPrice,
    apiOldPrice,
    image: apiProduct?.thumbnail?.url,
    labels: {
      inStock: apiProduct.stock_status === 'IN_STOCK',
    },
    stock: apiProduct.only_x_left_in_stock === null ? false : !!apiProduct.only_x_left_in_stock,
  };
}
