type IsoDateFormatModes = 'full' | 'no-year' | 'no-day';
type DateFormatModes = IsoDateFormatModes | 'only-weekday' | 'only-month';
type TimeFormatModes = 'full' | 'short';
type DateInput = string | Date | number;

/**
 * Gets the ISO date format in different forms depending on the inputted mode.
 * @param date The date to be formatted. Has to be a proper `Date` input, see: [Date Constructor](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date)
 * @param mode The mode to format the date according to
 * @example
 * const date = '2022-12-1';
 * //2022-12-01
 * getIsoDate(date, 'full');
 * //12-01
 * getIsoDate(date, 'no-year');
 * //2022-12
 * getIsoDate(date, 'no-day');
 * @returns The properly formatted ISO date
 */
export function getIsoDate(date?: DateInput, mode: IsoDateFormatModes = 'full') {
  const now = new Date(date || Date.now());
  if (isNaN(now.getTime())) {
    throw Error('Invalid date entered. The entered date: ' + date);
  }
  //the `slice(-2) is used to ensure that the number padding isn't making the day string more than 2 digits
  const day = ('0' + now.getDate()).slice(-2);
  //the `slice(-2) is used to ensure that the number padding isn't making the month string more than 2 digits
  const month = ('0' + (now.getMonth() + 1)).slice(-2);
  const year = now.getFullYear();

  switch (mode) {
    case 'no-day':
      return `${year}-${month}`;
    case 'no-year':
      return `${month}-${day}`;
    case 'full':
    default:
      return `${year}-${month}-${day}`;
  }
}

/**
 * Formats the date to Arabic formats in different forms depending on the inputted mode.
 * @param date The date to be formatted. Has to be a proper `Date` input, see: [Date Constructor](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date)
 * @param mode The mode to format the date according to
 * @example
 * const date = '2022-12-1';
 * //الخميس، ١ ديسمبر ٢٠٢٢
 * formatDate(date, 'full');
 * //الخميس، ١/‏١٢
 * formatDate(date, 'no-year');
 * //ديسمبر ٢٠٢٢
 * formatDate(date, 'no-day');
 * //الخميس
 * formatDate(date, 'only-weekday');
 * //ديسمبر
 * formatDate(date, 'only-month');
 * @returns The properly formatted date
 */
export function formatDate(date?: DateInput, mode: DateFormatModes = 'full') {
  const now = new Date(date || Date.now());
  if (isNaN(now.getTime())) {
    throw Error('Invalid date entered. The entered date: ' + date);
  }
  switch (mode) {
    case 'only-month':
      return now.toLocaleDateString('ar-EG', { month: 'long' });
    case 'only-weekday':
      return now.toLocaleDateString('ar-EG', { weekday: 'long' });
    case 'no-year':
      return now.toLocaleDateString('ar-EG', { weekday: 'long', month: 'numeric', day: 'numeric' });
    case 'no-day':
      return now.toLocaleDateString('ar-EG', { year: 'numeric', month: 'long' });
    case 'full':
    default:
      return now.toLocaleDateString('ar-EG', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  }
}

/**
 * Formats the time to Arabic formats in different forms depending on the inputted mode.
 * @param date The date containing time to be formatted. Has to be a proper `Date` input, see: [Date Constructor](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date)
 * @param mode The mode to format the date according to
 * @example
 * const time = '1970-01-01T12:30';
 * //١٢:٣٠ م
 * formatTime(date, 'full');
 * //١٢ م
 * formatTime(date, 'short');
 * @returns The properly formatted time
 */
export function formatTime(date?: DateInput, mode: TimeFormatModes = 'full') {
  const now = new Date(date || Date.now());
  if (isNaN(now.getTime())) {
    throw Error('Invalid date entered. The entered date: ' + date);
  }
  switch (mode) {
    case 'short':
      return now.toLocaleTimeString('ar-EG', { hour12: true, hour: 'numeric' });
    case 'full':
    default:
      return now.toLocaleTimeString('ar-EG', { hour12: true, hour: 'numeric', minute: 'numeric' });
  }
}

/**
 * Gets the ISO time format in different forms depending on the inputted mode.
 * @param date The date containing time to be formatted. Has to be a proper `Date` input, see: [Date Constructor](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date)
 * @param mode The mode to format the date according to
 * @example
 * const time = '1970-01-01T12:30';
 * //12:30:00
 * getIsoTime(date, 'full');
 * //12:30
 * getIsoTime(date, 'short');
 * @returns The properly formatted ISO time
 */
export function getIsoTime(date?: DateInput, mode: TimeFormatModes = 'short') {
  const now = new Date(date || Date.now());
  if (isNaN(now.getTime())) {
    throw Error('Invalid date entered. The entered date: ' + date);
  }
  switch (mode) {
    case 'full':
      return now.toLocaleTimeString('en', { hour12: false });
    case 'short':
    default:
      return now.toLocaleTimeString('en', { hour12: false, hour: 'numeric', minute: 'numeric' });
  }
}

/**
 * Generates an Arabic formatted number from the inputted number.
 * @param number The number to be formatted
 * @returns The properly formatted number
 */
export function formatNumber(number: number) {
  if (typeof number !== 'number') {
    throw Error('Must input a number, but the inpputed value was ' + typeof number);
  }
  if (isNaN(number)) {
    throw Error('The inputted value was NaN');
  }
  return number.toLocaleString('ar-EG');
}

/**
 * Converts a binary image file to a Base64 encoded string.
 *
 * This function reads the provided image file using a FileReader,
 * and returns a promise that resolves to the Base64 encoded string representation of the file's data.
 *
 * @param {File} image - The image file to be converted.
 * @returns {Promise<string | undefined>} A promise that resolves to the Base64 encoded string, or undefined if an error occurs.
 */
export function binaryImageToBase64(image: File): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Define the onload event handler for when the reading is complete
    reader.onload = function (e) {
      // e.target.result contains the Base64-encoded image data
      const base64ImageData = e?.target?.result as string;
      resolve(base64ImageData);
    };

    // Define the onerror event handler for potential errors
    reader.onerror = function (error) {
      reject(error);
    };

    // Read the file as Data URL (which includes Base64 encoding)
    reader.readAsDataURL(image);
  });
}
