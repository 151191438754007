import * as Types from '../../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type VehiclesBrandsQueryVariables = Types.Exact<{
  brandId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type VehiclesBrandsQuery = { __typename?: 'Query', response: Array<{ __typename?: 'VehicleBrand', id: string, is_user_defined: boolean, title: string } | null> };

export type VehicleModelsQueryVariables = Types.Exact<{
  brandId: Types.Scalars['ID'];
}>;


export type VehicleModelsQuery = { __typename?: 'Query', response: Array<{ __typename?: 'VehicleModel', id: string, is_user_defined: boolean, title: string } | null> };

export type AddVehicleToCustomerMutationVariables = Types.Exact<{
  vehicle: Types.VehicleInput;
}>;


export type AddVehicleToCustomerMutation = { __typename?: 'Mutation', response: { __typename?: 'Customer', firstname?: string | null } };

export type EditCustomerVehicleMutationVariables = Types.Exact<{
  vehicleUid: Types.Scalars['ID'];
  updateInput: Types.CustomerVehicleSpecificInput;
}>;


export type EditCustomerVehicleMutation = { __typename?: 'Mutation', response?: { __typename?: 'CustomerVehicle', name?: string | null } | null };

export type UnassignVehicleMutationVariables = Types.Exact<{
  vehicleUid: Types.Scalars['ID'];
  customerId: Types.Scalars['Int'];
}>;


export type UnassignVehicleMutation = { __typename?: 'Mutation', response: { __typename?: 'Customer', firstname?: string | null } };

export type DeleteVehicleMutationVariables = Types.Exact<{
  input: Types.Scalars['ID'];
}>;


export type DeleteVehicleMutation = { __typename?: 'Mutation', response: { __typename?: 'Customer', firstname?: string | null } };

export declare const VehiclesBrands: import("graphql").DocumentNode;
export declare const VehicleModels: import("graphql").DocumentNode;
export declare const AddVehicleToCustomer: import("graphql").DocumentNode;
export declare const EditCustomerVehicle: import("graphql").DocumentNode;
export declare const UnassignVehicle: import("graphql").DocumentNode;
export declare const DeleteVehicle: import("graphql").DocumentNode;

export const VehiclesBrandsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"VehiclesBrands"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"brandId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"vehiclesBrands"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"brandId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"brandId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"is_user_defined"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<VehiclesBrandsQuery, VehiclesBrandsQueryVariables>;
export const VehicleModelsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"VehicleModels"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"brandId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"vehicleModels"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"brandId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"brandId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"is_user_defined"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<VehicleModelsQuery, VehicleModelsQueryVariables>;
export const AddVehicleToCustomerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddVehicleToCustomer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"vehicle"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"VehicleInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"addVehicle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"vehicle"},"value":{"kind":"Variable","name":{"kind":"Name","value":"vehicle"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstname"}}]}}]}}]} as unknown as DocumentNode<AddVehicleToCustomerMutation, AddVehicleToCustomerMutationVariables>;
export const EditCustomerVehicleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditCustomerVehicle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"vehicleUid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"updateInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CustomerVehicleSpecificInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"updateVehicle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"vehicleUid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"vehicleUid"}}},{"kind":"Argument","name":{"kind":"Name","value":"updateInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"updateInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<EditCustomerVehicleMutation, EditCustomerVehicleMutationVariables>;
export const UnassignVehicleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnassignVehicle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"vehicleUid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"customerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"unassignVehicleFromCustomer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"vehicleUid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"vehicleUid"}}},{"kind":"Argument","name":{"kind":"Name","value":"customerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"customerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstname"}}]}}]}}]} as unknown as DocumentNode<UnassignVehicleMutation, UnassignVehicleMutationVariables>;
export const DeleteVehicleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteVehicle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"removeVehicle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"vehicleUid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstname"}}]}}]}}]} as unknown as DocumentNode<DeleteVehicleMutation, DeleteVehicleMutationVariables>;