import { AssignJsmRiderDocument, RidersDocument, RidersQueryVariables } from '@/graphql/Riders';
import { toNonNullable } from '@/utils/collections';
import { Ref, ref, watch } from '@vue/composition-api';
import { Admin, AssignJsmRiderInput, BookedItem, SearchResultPageInfo, StoreBooking } from 'graphql-types.gen';
import { MaybeReactive, useMutation, useQuery } from 'villus';
import { mapCalendarBookingOrder } from '@/features/bookings';

export function useRiders(variables?: MaybeReactive<RidersQueryVariables>) {
  const riders: Ref = ref([]);
  const totalCount: Ref<number> = ref(0);
  const pageInfo: Ref<SearchResultPageInfo> = ref({});

  const { data, isFetching, execute, unwatchVariables } = useQuery({
    query: RidersDocument,
    variables,
    cachePolicy: 'network-only',
  });

  unwatchVariables();

  watch(data, value => {
    riders.value = toNonNullable(value?.admins?.items as Admin[])?.map(mapRider);
    totalCount.value = value?.admins?.total_count as number;
    pageInfo.value = value?.admins?.page_info as SearchResultPageInfo;
  });

  return {
    riders,
    totalCount,
    pageInfo,
    isFetchingRiders: isFetching,
    fetchRiders: execute,
  };
}

function mapRider(apiRider: Admin) {
  return {
    fullName: `${apiRider.firstname} ${apiRider.lastname}`,
    completedRequestsNo: apiRider?.completed_jsm_requests?.total_count,
    branches: apiRider?.store_locators?.items,
    status: apiRider?.active_jsm_request?.status?.toLowerCase() || '',
    ...(apiRider?.active_jsm_request?.booking && {
      booking: mapActiveBookingOrder(apiRider?.active_jsm_request?.booking),
    }),
  };
}

// On map active booking order
function mapActiveBookingOrder(apiOrder: StoreBooking) {
  const orderBooking = toNonNullable(apiOrder?.items?.filter(service => service?.booking) as Partial<BookedItem>[])
    .map(booking => mapCalendarBookingOrder(booking, apiOrder))
    .flat();

  return orderBooking?.length ? orderBooking[0] : null;
}

export function useAssginRider() {
  const { execute, isFetching } = useMutation(AssignJsmRiderDocument);

  async function assgin(input: AssignJsmRiderInput) {
    try {
      const { data, error } = await execute({ input });

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return { assgin, isFetching };
}
