<template>
  <component
    :is="as"
    class="AppButton"
    :class="{
      'is-transparent border-black text-black': variant === 'transparent',
      'text-white bg-black': variant === 'default',
    }"
    v-bind="$attrs"
    :aria-label="ariaLabel"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    as: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'default',
    },
    ariaLabel: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="postcss" scoped>
.AppButton {
  @apply p-4 text-sm relative font-bold;
  display: block;
  min-width: 228px;
  min-height: 48px;
  text-align: center;
  border-radius: 6px;

  &.is-transparent {
    border: 1px solid;
    background-color: transparent;

    &:disabled {
      @apply bg-transparent opacity-50;
      pointer-events: none;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

/* rtl:begin:ignore */
[dir='rtl'] {
  svg {
    transform: scaleX(-1);
  }
}
/* rtl:end:ignore */
</style>
