<template>
  <ul class="flex items-center gap-x-40">
    <li v-for="(step, idx) in steps" :key="idx" class="Step" :class="{ 'Step--active': idx + 1 <= currentStep }">
      <span class="Step__Number">{{ idx + 1 }}</span>
      <span>{{ step }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'StepsProgress',
  props: {
    currentStep: {
      type: String,
      required: true,
      validator: function (value) {
        // Steps are from 1 to 3 only
        return value && value < 4;
      },
    },
    steps: {
      type: Array,
      default: () => ['إختر العميل', 'إختر الخدمات', 'إختر تفاصيل الحجز'],
    },
  },
};
</script>

<style lang="postcss" scoped>
.Step {
  @apply relative text-gray-400 font-bold;

  &__Number {
    @apply inline-block ml-3 border-gray-400 h-8 w-8 rounded-full text-center text-sm leading-7;
    border: 3px solid;
  }

  &:not(:last-child)::after {
    @apply bg-gray-200;
    content: '';
    width: 140px;
    height: 3px;
    border-radius: 8px;

    position: absolute;
    inset-inline-start: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
  }

  &--active {
    @apply text-black;
    .Step__Number {
      @apply border-blue-100;
    }
  }
}
</style>
