import { SelectOption } from '@/utils/serviceOptions';
import { eachHourOfInterval, endOfToday, startOfToday } from 'date-fns';

export function useTimeOptions() {
  // Populate time array with every hour
  const hoursInInterval = eachHourOfInterval({ start: startOfToday(), end: endOfToday() });

  const times: SelectOption[] = hoursInInterval.map(date => {
    return {
      // Get hour numeric value without leading zeros
      value: date.toLocaleTimeString('en-US', { hour: 'numeric', hourCycle: 'h24' }).replace(/^0+/, ''),
      label: date.toLocaleTimeString('ar-EG', {
        hour: '2-digit',
        minute: '2-digit',
      }),
    };
  });

  return {
    value: times,
  };
}
