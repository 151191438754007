import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ar from 'vuetify/src/locale/ar';
import 'vuetify/dist/vuetify.min.css';
import { UserVuetifyPreset } from 'vuetify';

Vue.use(Vuetify);

const opts: Partial<UserVuetifyPreset> = {
  iconfont: 'mdiSvg',
  rtl: true,
  lang: {
    locales: { ar },
    current: 'ar',
  },
};

export default new Vuetify(opts);
