<template>
  <ValidationProvider v-slot="{ errors }" ref="provider" :custom-messages="messages" :rules="rules" :name="label" slim>
    <div class="Checkbox">
      <div :class="{ 'has-errors': !!errors[0] }" class="Checkbox__Control">
        <label :for="id">
          <slot>
            {{ label }}
          </slot>
        </label>
        <input
          :id="id"
          class="absolute inset-0"
          :checked="isChecked"
          :value="value"
          :name="name"
          type="checkbox"
          aria-hidden="true"
          tabindex="-1"
          @change="toggle"
        />
        <span class="Checkbox__Input">
          <slot name="checkbox">
            <button
              role="switch"
              :aria-checked="isChecked ? 'true' : 'false'"
              :title="label"
              class="Checkbox__Btn flex-shrink-0 border flex items-center justify-center"
              :class="{ 'is-active': isChecked }"
              type="button"
              @click="toggle"
            >
              <svg width="14" height="14" role="presentation" :class="{ invisible: !isChecked }">
                <use xlink:href="@/assets/svg/symbol/svg/sprite.symbol.svg#check"></use>
              </svg>
            </button>
          </slot>
        </span>
      </div>
      <span class="text-red-100 text-xs absolute">{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Checkbox',
  components: {
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    messages: {
      type: Object,
      default: null,
    },
    value: {
      type: null,
      default: true,
    },
    // Customizes the value set on the model when the checkbox is checked
    checkedValue: {
      type: null,
      default: true,
    },
    // Customizes the value set on the model when the checkbox is unchecked.
    // Has no effect when the input is bound to an array.
    uncheckedValue: {
      type: null,
      default: false,
    },
    // the attribute name of the current item being changed (if we're passing an object of values)
    propName: {
      type: String,
      default: undefined,
    },
    variant: {
      type: String,
      default: '',
    },
  },
  computed: {
    /**
     * handles checking or unchecking checkbox
     * returns true to check and false to uncheck
     */
    isChecked() {
      // if we have an array of selected values ex: categories filters
      if (Array.isArray(this.value)) {
        // return true if the selected values' array contains the checkbox's checkedValue
        return this.value.includes(this.checkedValue);
      }
      // if we have an object of all values ex: more filters && the value is not null
      if (typeof this.value === 'object' && this.value) {
        // compare the current item's value in the object with the checkbox's checkedValue
        return this.value[this.propName] === this.checkedValue;
      }
      // otherwise we're passing a single value
      return this.value === this.checkedValue;
    },
  },
  watch: {
    value(value) {
      this.$refs.provider.syncValue(value);
    },
  },
  methods: {
    /**
     * handles toggling the checkbox's state
     */
    toggle() {
      // if have an object of values
      if (typeof this.value === 'object' && this.value && !Array.isArray(this.value)) {
        // then shallow clone the object to handle mutability
        // and toggle the value of the current selected item
        // and emit the event with the new object
        return this.$emit('input', {
          ...this.value,
          [this.propName]: this.isChecked ? this.uncheckedValue : this.checkedValue,
        });
      }
      // if we have a single value and current item is checked
      if (this.isChecked && !Array.isArray(this.value)) {
        return this.$emit('input', this.uncheckedValue);
      }
      // if we have an array of values and checkbox is currently checked
      if (Array.isArray(this.value) && this.isChecked) {
        // filter out the current value from the array
        // and emit the input event with the filtered array
        return this.$emit(
          'input',
          this.value.filter(val => val !== this.checkedValue)
        );
      }

      const newVal = Array.isArray(this.value) ? [...this.value, this.checkedValue] : this.checkedValue;
      this.$emit('input', newVal);
    },
  },
};
</script>

<style lang="postcss" scoped>
/* purgecss start ignore */
.Checkbox {
  @apply relative text-black;

  &__Input {
    @apply w-6 h-6 ml-2;
    order: -1;
    transition: background-color 0.2s ease-in-out;
  }

  &__Control {
    @apply flex items-center font-normal text-sm;
    input {
      @apply opacity-0;
    }
    label {
      @apply cursor-pointer;
    }
  }

  &__Btn {
    @apply w-6 h-6 outline-none rounded-sm;
    border: 2px solid #d8d4d4;

    &.is-active {
      @apply border-gray-400;
    }
  }
}
/* purgecss end ignore */
</style>
