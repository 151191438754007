import {
  ServicesDocument,
  BranchServicesDocument,
  AddOnsDocument,
  GetAvailableTimesDocument,
  ServiceSelectionDocument,
} from '@/graphql/Services';
import { toNonNullable } from '@/utils/collections';
import { useQuery } from 'villus';
import { computed, ComputedRef, ref } from '@vue/composition-api';
import { CategoryInterface, CustomAttribute } from 'graphql-types.gen';
import { resolveProductPrice } from '@/utils/products';

export type MappedService = ReturnType<typeof mapService>;

export function useServices() {
  const { execute, isFetching } = useQuery({
    query: ServicesDocument,
    fetchOnMount: false,
    variables: {
      pageSize: 100,
    },
  });

  async function getServices() {
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    return toNonNullable(data?.services?.nodes) || [];
  }

  return {
    getServices,
    isFetchingServices: isFetching,
  };
}

export function useServiceSelection() {
  const { data, isFetching } = useQuery({
    query: ServiceSelectionDocument,
    variables: {
      pageSize: 100,
    },
  });

  const selectedServiceCategory = ref('car-washing');
  // Get services currently enabled for the branch
  const services: ComputedRef<MappedService[]> = computed(() => (data.value?.services?.nodes ?? []).map(mapService));
  // Get categories from services available
  // To be used in top level services dropdown
  const categories: ComputedRef<CategoryInterface[]> = computed(function () {
    return services.value?.reduce(function (acc, curr) {
      const serviceCategory = curr?.categories?.[curr.categories.length - 1];
      if (!acc.some((category: CategoryInterface) => category.url_key === serviceCategory?.url_key)) {
        acc.push(serviceCategory as CategoryInterface);
      }

      return acc;
    }, [] as CategoryInterface[]);
  });

  // On updating top-level category, get relevant services
  const servicesByCategory: ComputedRef<MappedService[]> = computed(() =>
    services.value
      ?.filter(service =>
        service?.categories?.some((category: CategoryInterface) => category?.url_key === selectedServiceCategory.value)
      )
      .map(mapService)
  );

  return {
    services,
    selectedServiceCategory,
    servicesByCategory,
    categories,
    isFetchingServices: isFetching,
  };
}

export function useBranchServices(id: number) {
  const { data, isFetching } = useQuery({ query: BranchServicesDocument, variables: { id } });

  const selectedServiceCategory = ref('car-washing');
  // Get services currently enabled for the branch
  const services: ComputedRef<MappedService[]> = computed(
    () =>
      data.value?.branch?.availableServices?.nodes?.filter(s => s?.bookableService?.isEnabledInStore) as MappedService[]
  );
  // Get categories from services available
  // To be used in top level services dropdown
  const categories: ComputedRef<CategoryInterface[]> = computed(function () {
    return services.value?.reduce(function (acc, curr) {
      const serviceCategory = curr?.categories?.[curr.categories.length - 1];
      if (!acc.some((category: CategoryInterface) => category.url_key === serviceCategory?.url_key)) {
        acc.push(serviceCategory as CategoryInterface);
      }

      return acc;
    }, [] as CategoryInterface[]);
  });

  // On updating top-level category, get relevant services
  const servicesByCategory: ComputedRef<MappedService[]> = computed(() =>
    services.value
      ?.filter(service =>
        service?.categories?.some((category: CategoryInterface) => category?.url_key === selectedServiceCategory.value)
      )
      .map(mapService)
  );

  return {
    services,
    selectedServiceCategory,
    servicesByCategory,
    categories,
    isFetchingServices: isFetching,
  };
}

export function useGetAvailableTimes() {
  const { execute, isFetching } = useQuery({
    query: GetAvailableTimesDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  async function getAvailableTimes(bookableServiceUid: string, bookingDay: string) {
    const { data, error } = await execute({ variables: { bookableServiceUid, bookingDay } });

    if (error) {
      throw new Error(error.message);
    }

    return data?.availableTimes;
  }

  return {
    getAvailableTimes,
    isFetchingTimes: isFetching,
  };
}

export function mapService(apiProduct: any) {
  if (!apiProduct) {
    return;
  }

  const currentPrice = resolveProductPrice(apiProduct);
  const oldPrice = apiProduct.price_range.maximum_price?.regular_price.value ?? currentPrice;
  const apiPrice = resolveProductPrice(apiProduct);
  const apiOldPrice = apiProduct.price_range.maximum_price?.regular_price.value ?? currentPrice;

  const service = {
    ...apiProduct,
    uid: apiProduct.bookableService?.uid,
    isEnabledInStore: apiProduct.bookableService?.isEnabledInStore,
    storeSlotDuration: apiProduct.bookableService?.storeSlotDuration,
    averageDuration: apiProduct.averageDuration || 0,
    price: resolveProductPrice(apiProduct),
    priceBefore: currentPrice < oldPrice ? oldPrice : undefined,
    discountPercentage: currentPrice / oldPrice !== 1 ? Math.round(((oldPrice - currentPrice) / oldPrice) * 100) : 0,
    apiPrice,
    apiOldPrice,
    attributes:
      apiProduct.attributes?.filter(
        (attribute: CustomAttribute) => attribute.value === 'Yes' && attribute.key !== 'average_duration'
      ) || [],
  };

  return service;
}

export function useAddOns() {
  const { execute, isFetching } = useQuery({
    query: AddOnsDocument,
    fetchOnMount: false,
  });

  async function getAddOns() {
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    return toNonNullable(data?.services?.nodes?.map(mapService)) || [];
  }

  return {
    getAddOns,
    isFetchingAddOns: isFetching,
  };
}
