import { CookieAttributes } from 'js-cookie';

interface CookiesApi {
  readonly cookies: Record<string, any>;
  setCookie(name: string, value: string, attrs?: CookieAttributes): void;
  removeCookie(name: string, attrs?: CookieAttributes): void;
}

let useCookies: () => CookiesApi;

const DEFAULT_ATTRS = {
  path: '/',
};

const jsCookies = require('js-cookie');

useCookies = function useClientCookies(): CookiesApi {
  const cookies = jsCookies.get() as Record<string, any>;

  return {
    cookies,
    setCookie(name, value, attrs = {}) {
      jsCookies.set(name, value, { ...DEFAULT_ATTRS, ...attrs });
    },
    removeCookie(name, attrs = {}) {
      jsCookies.remove(name, { ...DEFAULT_ATTRS, ...attrs });
    },
  };
};

export default useCookies;
