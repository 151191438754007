import {
  CreateAddressDocument,
  CreateCustomerDocument,
  CustomerDocument,
  CustomersDocument,
  CustomersQueryVariables,
  GenerateCustomerTokenDocument,
  GetCustomerByTokenDocument,
  UpdateCustomerDocument,
  UpdateCustomerPhoneNumberDocument,
  CreateCustomerAndAddressDocument,
} from '@/graphql/Customers';
import { watch, ref, Ref } from '@vue/composition-api';
import { MaybeReactive, useMutation, useQuery } from 'villus';
import { toNonNullable } from '@/utils/collections';
import {
  BookedService,
  Customer,
  CustomerAddressInput,
  CreateCustomerAndAddressInput,
  CreateCustomerInput,
  CustomerUpdateAsAdminInput,
  GenerateCustomerTokenAsAdminInput,
  SearchResultPageInfo,
  UpdateCustomerPhoneNumberInput,
  CustomerVehicle,
} from 'graphql-types.gen';
import useCookies from './cookies';
import { mapCalendarBooking } from './bookings';
import { useMutationWrapper } from '@/features/wrappers';

export function useCustomers(variables?: MaybeReactive<CustomersQueryVariables>, fetchOnMount: boolean = true) {
  const customers: Ref<Partial<Customer>[]> = ref([]);
  const totalCount: Ref<number> = ref(0);
  const pageInfo: Ref<SearchResultPageInfo> = ref({});

  const { data, isFetching, execute, unwatchVariables } = useQuery({
    query: CustomersDocument,
    variables,
    cachePolicy: 'network-only',
    fetchOnMount,
  });

  unwatchVariables();

  watch(data, value => {
    customers.value = toNonNullable(value?.customers?.items as Customer[]);
    totalCount.value = value?.customers?.totalCount as number;
    pageInfo.value = value?.customers?.page_info as SearchResultPageInfo;
  });

  return {
    customers,
    totalCount,
    pageInfo,
    isFetchingCustomers: isFetching,
    fetchCustomers: execute,
  };
}

export function useCustomer(variables?: MaybeReactive<CustomersQueryVariables>) {
  const customer = ref();

  const { data, isFetching, execute } = useQuery({
    query: CustomerDocument,
    variables,
    fetchOnMount: false,
  });

  watch(data, value => {
    customer.value = toNonNullable(value?.customers?.items)[0] as Customer;
  });

  return {
    customer,
    isFetchingCustomer: isFetching,
    execute,
  };
}

export function useCustomerIdentity(
  withVehicles: boolean = false,
  withBookings: boolean = false,
  fetchOnMount: boolean = true
) {
  const customer = ref();
  const { data, execute } = useQuery({
    query: GetCustomerByTokenDocument,
    variables: { withVehicles, withBookings },
    cachePolicy: 'network-only',
    fetchOnMount,
  });

  watch(data, value => {
    customer.value = value?.customer;

    if (withBookings) {
      customer.value.bookings = (value?.customer as Customer).orders?.items
        ?.map(order =>
          order?.items?.map(booking => {
            if (!booking?.booked_service) {
              return;
            }

            return {
              ...mapCalendarBooking(booking?.booked_service as BookedService),
              name: booking?.product_name,
              orderNumber: order?.number,
              vehicleLabel: `${order?.vehicle?.brand} — ${order?.vehicle?.model}`,
            };
          })
        )
        .flat()
        .filter(booking => booking);
    }
  });

  return {
    customer,
    refetchCustomer: execute,
  };
}

export function useCreateCustomer() {
  return useMutationWrapper<CreateCustomerInput>(CreateCustomerDocument, 'response.customer');
}

export function useCreateCustomerAndAddress() {
  return useMutationWrapper<CreateCustomerAndAddressInput>(CreateCustomerAndAddressDocument, 'response.customer');
}

export function useGenerateCustomerToken() {
  const { execute, isFetching } = useMutation(GenerateCustomerTokenDocument);
  const { setCookie } = useCookies();

  async function generateToken(input: GenerateCustomerTokenAsAdminInput) {
    try {
      const { data, error } = await execute({ input });

      if (error) {
        throw new Error(error.message);
      }

      setCookie('customerToken', data?.response?.token as string, {
        expires: new Date(Date.now() + 1000 * 60 * 60),
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    generateToken,
    isGeneratingToken: isFetching,
  };
}

export function useCreateAddress() {
  return useMutationWrapper<CustomerAddressInput>(CreateAddressDocument, '');
}

export function useUpdateCustomerData() {
  const { execute, isFetching } = useMutation(UpdateCustomerDocument);

  async function updateCustomerData(email: string, input: CustomerUpdateAsAdminInput) {
    try {
      const { error } = await execute({ email, input });

      if (error) {
        throw new Error(error.message);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updateCustomerData,
    isUpdatingCustomerData: isFetching,
  };
}

export function useUpdateCustomerPhone() {
  return useMutationWrapper<UpdateCustomerPhoneNumberInput>(UpdateCustomerPhoneNumberDocument, '');
}

/**
 * Selects the default vehicle from a list of customer vehicles.
 * If no default vehicle is set, it returns the first available vehicle.
 * If there are no vehicles, it returns undefined.
 *
 * @param {CustomerVehicle[]} vehicles - The list of customer vehicles.
 * @returns {CustomerVehicle | undefined} - The default vehicle or the first vehicle, or undefined if no vehicles are available.
 */
export function selectDefaultVehicle(vehicles: CustomerVehicle[]): CustomerVehicle | undefined {
  if (!vehicles?.length) return;

  const defaultVehicle = vehicles.find(vehicle => vehicle?.is_default);
  return defaultVehicle ?? vehicles[0];
}
