<template>
  <svg :width="width" :height="height" role="presentation">
    <use :xlink:href="`${require(`@/assets/svg/symbol/svg/sprite.symbol.svg`)}#${name}`"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
    },
    width: {
      type: [Number, String],
      default: 14,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
  },
};
</script>
