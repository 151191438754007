import { useMutation } from 'villus';
import { get } from 'lodash-es';

// mutation wrapper
export function useMutationWrapper<TInput>(mutationDocument: any, path: string) {
  const { execute, isFetching } = useMutation(mutationDocument);

  async function mutate(input: TInput) {
    try {
      const { data, error } = await execute({ input });

      if (error) {
        throw new Error(error.message);
      }

      return get(data, path);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return {
    mutate,
    isFetching,
  };
}
