import { render, staticRenderFns } from "./EditJumpstartModal.vue?vue&type=template&id=52969f58&scoped=true&"
import script from "./EditJumpstartModal.vue?vue&type=script&lang=ts&"
export * from "./EditJumpstartModal.vue?vue&type=script&lang=ts&"
import style0 from "./EditJumpstartModal.vue?vue&type=style&index=0&id=52969f58&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52969f58",
  null
  
)

export default component.exports