import { isBefore, isAfter, endOfDay } from 'date-fns';
import { PriceRange, ProductInterface } from '../../graphql-types.gen';

/**
 * Resolves the current price of the item.
 */
export function resolveProductPrice(
  product: Pick<ProductInterface, 'special_price' | 'special_from_date' | 'special_to_date'> & {
    price_range?: Partial<PriceRange>;
  }
): number {
  const specialPrice = product.special_price;
  const basePrice = product.price_range?.maximum_price?.final_price.value ?? 0;
  if ((!product.special_from_date || !product.special_to_date) && specialPrice) {
    return specialPrice;
  }

  const offerFrom = product.special_from_date ? new Date(product.special_from_date) : null;
  const offerTo = product.special_to_date ? endOfDay(new Date(product.special_to_date)) : null;
  const now = new Date();
  const isValidOffer =
    specialPrice && specialPrice !== null && offerTo && offerFrom && isAfter(offerTo, now) && isBefore(offerFrom, now);
  if (isValidOffer) {
    return specialPrice ?? basePrice;
  }

  return basePrice;
}
