import {
  AddAdminUserDocument,
  AdminRolesDocument,
  DeleteUserDocument,
  UpdateAdminUserDocument,
  UsersDocument,
  UsersQueryVariables,
} from '@/graphql/Users';
import { toNonNullable } from '@/utils/collections';
import { computed, Ref, ref, watch } from '@vue/composition-api';
import {
  AddNewAdminUserInput,
  Admin,
  AdminRole,
  SearchResultPageInfo,
  UpdateAdminUserInput,
  UsersFilterInput,
} from 'graphql-types.gen';
import { MaybeReactive, useMutation, useQuery } from 'villus';

export function useUsers(variables?: MaybeReactive<UsersQueryVariables>) {
  const users: Ref<Partial<Admin>[]> = ref([]);
  const totalCount: Ref<number> = ref(0);
  const pageInfo: Ref<SearchResultPageInfo> = ref({});

  const { data, isFetching, execute, unwatchVariables } = useQuery({
    query: UsersDocument,
    variables,
    cachePolicy: 'network-only',
  });

  unwatchVariables();

  watch(data, value => {
    users.value = toNonNullable(value?.admins?.items as Admin[]);
    totalCount.value = value?.admins?.total_count as number;
    pageInfo.value = value?.admins?.page_info as SearchResultPageInfo;
  });

  return {
    users,
    totalCount,
    pageInfo,
    isFetchingUsers: isFetching,
    fetchUsers: execute,
  };
}

export function useUser(filter: UsersFilterInput) {
  const { isFetching, execute } = useQuery({
    query: UsersDocument,
    variables: {
      filter,
    },
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  return {
    isFetchingUser: isFetching,
    fetchUser: execute,
  };
}

export function useAdminRoles() {
  const { data, isFetching } = useQuery({ query: AdminRolesDocument });

  const roles = computed(() => data.value?.adminRoles.items as AdminRole[]);

  return {
    roles,
    isFetchingRoles: isFetching,
  };
}

export function useAddUser() {
  const { execute, isFetching } = useMutation(AddAdminUserDocument);

  async function addUser(input: AddNewAdminUserInput) {
    try {
      const { data, error } = await execute({ input });

      if (error) {
        throw new Error(error.message);
      }

      return data.response.admin.username;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    addUser,
    isAddingUser: isFetching,
  };
}

export function useUpdateUserData() {
  const { execute, isFetching } = useMutation(UpdateAdminUserDocument);

  async function updateUserData(username: string, input: UpdateAdminUserInput) {
    try {
      const { data, error } = await execute({ username, input });

      if (error) {
        throw new Error(error.message);
      }

      return data.response.admin;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updateUserData,
    isUpdatingUserData: isFetching,
  };
}

export function useDeleteUser() {
  const { execute, isFetching } = useMutation(DeleteUserDocument);

  async function deleteUser(username: string) {
    try {
      const { data, error } = await execute({ username });

      if (error) {
        throw new Error(error.message);
      }

      return data.response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    deleteUser,
    isDeletingUser: isFetching,
  };
}
