<template>
  <nav>
    <ul class="flex items-center justify-center overflow-x-auto">
      <li>
        <button
          :disabled="currentPage === 1"
          class="ml-9"
          :class="{ 'opacity-50 pointer-events-none': currentPage === 1 }"
          @click="prev"
        >
          السابق
        </button>
      </li>
      <li v-for="(item, idx) in range" :key="idx">
        <button v-if="item.isSeparator" disabled class="pointer-events-none">...</button>
        <button
          v-else
          class="mx-1"
          :class="{
            'text-gray-300': item.page !== currentPage,
          }"
          @click="goToPage(item.page)"
        >
          {{ item.page }}
        </button>
      </li>
      <li>
        <button
          :disabled="currentPage === totalPages"
          class="mr-9"
          :class="{ 'opacity-50 pointer-events-none': currentPage === totalPages }"
          @click="next"
        >
          التالي
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    range() {
      let range = [{ page: 1 }];
      if (this.currentPage - 3 > 1) {
        range.push({ isSeparator: true });
      }

      for (let i = 2; i >= 0; i--) {
        if (this.currentPage - i === 1) continue;

        range.push({ page: this.currentPage - i });
      }

      range = range.filter(n => n.isSeparator || n.page > 0);
      if (this.currentPage === this.totalPages) {
        return range;
      }

      for (let i = 1; i <= 2; i++) {
        if (this.currentPage + i > this.totalPages) break;

        range.push({ page: this.currentPage + i });
      }

      if (range[range.length - 1].page === this.totalPages) {
        return range;
      }

      range.push({ isSeparator: true }, { page: this.totalPages });

      return range;
    },
  },
  methods: {
    prev() {
      this.goToPage(this.currentPage - 1);
    },
    next() {
      this.goToPage(this.currentPage + 1);
    },
    goToPage(page) {
      if (page < 1 || page > this.totalPages) {
        return;
      }

      this.$emit('change', page);
    },
  },
};
</script>
