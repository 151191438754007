import { BranchesCitiesDocument, BranchesRegionsDocument } from '@/graphql/Branches';
import { CityDocument } from '@/graphql/Country';
import { computed, ComputedRef } from '@vue/composition-api';
import { City, Region } from 'graphql-types.gen';
import { useQuery } from 'villus';

export function useRegions() {
  const { data, isFetching } = useQuery({ query: BranchesRegionsDocument, cachePolicy: 'network-only' });

  const regions: ComputedRef<Region[]> = computed(
    () =>
      data.value?.branches?.nodes?.reduce((acc, curr) => {
        if (!acc.some(region => region.id === curr?.region?.id)) {
          acc.push(curr?.region as Region);
        }
        return acc;
      }, [] as Region[]) || []
  );

  return {
    regions,
    isFetchingRegions: isFetching,
  };
}

export function useRegion(regionId: number) {
  const { data, isFetching } = useQuery({
    query: BranchesCitiesDocument,
    variables: { regionId },
    cachePolicy: 'network-only',
  });

  const region = computed(() => data.value?.branches?.nodes?.[0]?.region);
  const cities: ComputedRef<City[]> = computed(
    () =>
      data.value?.branches?.nodes?.reduce((acc, curr) => {
        if (!acc.some(city => city.id === curr?.city?.id)) {
          acc.push(curr?.city as City);
        }
        return acc;
      }, [] as City[]) || []
  );

  return {
    region,
    cities,
    isFetchingRegion: isFetching,
  };
}

export function useCity(cityId: string) {
  const { data } = useQuery({ query: CityDocument, variables: { cityId }, cachePolicy: 'network-only' });

  const city = computed(() => data.value?.city || []);

  return {
    city,
  };
}
