import { OrderByNumberDocument } from '@/graphql/Orders';
import { toNonNullable } from '@/utils/collections';
import { CustomerOrder } from 'graphql-types.gen';
import { useQuery } from 'villus';

export type OrderType = ReturnType<typeof mapOrder>;

export function useOrderByNumber() {
  const { execute, isFetching } = useQuery({
    query: OrderByNumberDocument,
    cachePolicy: 'network-only',
  });

  async function getOrderByNumber(orderNumber: string) {
    const { data, error } = await execute({ variables: { orderNumber } });
    if (error) {
      throw new Error(error.message);
    }

    return mapOrder(data?.order as CustomerOrder);
  }

  return {
    getOrderByNumber,
    isFetchingOrder: isFetching,
  };
}

function mapOrder(order: CustomerOrder) {
  const mappedOrder = {
    orderNumber: order?.number,
    isJumpstart: order.is_jump_start_order,
    subtotal: order.total?.subtotal.value,
    discount: order.total?.discounts?.[0],
    total: order.total?.grand_total.value,
    batteryImage: order?.jump_start_me?.battery_image,
    rider: order?.jump_start_me?.rider,
    location: order?.jump_start_me?.location,
    status: order?.jump_start_me?.status || '',
    paymentMethod: order?.payment_methods?.length ? order?.payment_methods[0]?.name : '',
    items: toNonNullable(order.items).map(item => {
      return {
        id: item?.id,
        sku: item?.product_sku,
        name: !item?.booked_service ? `+ ${item?.product_name}` : item?.product_name,
        bookableServiceUid: item?.booked_service?.bookable_service_uid,
        storeSlotDuration: item?.booked_service?.store_duration_slots,
        oldPrice: item?.product_original_price.value,
        newPrice: item?.product_sale_price.value,
        discounted: item?.product_sale_price.value !== item?.product_original_price.value,
      };
    }),
  };

  // To keep add ons at the end of the order details
  // We identify add ons by undefined bookable service data, as they are not scheduled
  const services = mappedOrder.items?.filter(item => item.bookableServiceUid);
  const addOns = mappedOrder.items?.filter(item => !item.bookableServiceUid);

  mappedOrder.items = services?.concat(addOns);

  return mappedOrder;
}
