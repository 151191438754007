import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/tailwind.css';
import './assets/css/typography.css';
import './assets/css/transitions.css';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import './plugins/google-maps';
import './plugins/portal';

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
