import {
  AddVehicleToCustomerDocument,
  DeleteVehicleDocument,
  EditCustomerVehicleDocument,
  UnassignVehicleDocument,
  VehiclesBrandsDocument,
  VehicleModelsDocument,
  VehicleModelsQueryVariables,
} from '@/graphql/Vehicles';
import { computed } from '@vue/composition-api';
import { MaybeReactive, useMutation, useQuery } from 'villus';
import { useMutationWrapper } from '@/features/wrappers';
import { CustomerVehicle } from 'graphql-types.gen';

export function useVehiclesBrands() {
  const { data, isFetching } = useQuery({ query: VehiclesBrandsDocument });

  const brands = computed(() => data.value?.response);

  return {
    brands,
    isFetchingVehicleBrands: isFetching,
  };
}

export function useVehiclesModelsByBrand(variables: MaybeReactive<VehicleModelsQueryVariables>) {
  const {
    data,
    isFetching,
    execute: refetchModels,
    unwatchVariables,
  } = useQuery({ query: VehicleModelsDocument, variables, fetchOnMount: false });
  unwatchVariables();

  const vehiclesByBrand = computed(() => {
    return data.value?.response?.map(item => {
      return { id: item?.id, title: item?.title };
    });
  });

  return {
    refetchModels,
    value: vehiclesByBrand,
    isFetchingModels: isFetching,
  };
}

/**
 * Represents the type of a vehicle to be added.
 * @typedef {Object} VehicleToBeAddedType
 * @property {({ id: string, title: string } | string)} brand - The brand of the vehicle. Can be an object with id and title properties or a string.
 * @property {({ id: string, title: string } | string)} model - The model of the vehicle. Can be an object with id and title properties or a string.
 * @property {string} year - The year of the vehicle.
 * @property {string} [id] - Optional identifier for the vehicle.
 */
export type VehicleToBeAddedType = Partial<{
  brand: { id: string; title: string } | string;
  model: { id: string; title: string } | string;
  year: string;
}>;

export function useAddVehicleToCustomer() {
  const { execute, isFetching } = useMutation(AddVehicleToCustomerDocument);

  async function addVehicle(vehicle: VehicleToBeAddedType) {
    try {
      const { error } = await execute({
        vehicle: mapVehicleVariables(vehicle),
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  async function addCustomVehicle(vehicle: VehicleToBeAddedType) {
    try {
      const { error } = await execute({ vehicle: mapVehicleVariables(vehicle) });

      if (error) {
        throw new Error(error.message);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    addVehicle,
    addCustomVehicle,
    isAddingVehicle: isFetching,
  };
}

export function useEditVehicleToCustomer() {
  const { execute, isFetching } = useMutation(EditCustomerVehicleDocument);

  async function editVehicle(vehicleUid: string, updateInput: VehicleToBeAddedType) {
    try {
      const { error } = await execute({ vehicleUid, updateInput: mapVehicleVariables(updateInput) });

      if (error) {
        throw new Error(error.message);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    editVehicle,
    isEditingVehicle: isFetching,
  };
}

/**
 * Unassigns a vehicle from a customer
 * To be able to add a custom vehicle in case of editing
 */
export function useUnassignVehicle() {
  const { execute, isFetching } = useMutation(UnassignVehicleDocument);

  async function unassignVehicle(vehicleUid: string, customerId: number) {
    try {
      const { error } = await execute({ vehicleUid, customerId });

      if (error) {
        throw new Error(error.message);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    unassignVehicle,
    isUnassigningVehicle: isFetching,
  };
}

export function useDeleteVehicle() {
  return useMutationWrapper<string>(DeleteVehicleDocument, '');
}

/**
 * Maps vehicle properties to a simplified object format.
 * @param {VehicleToBeAddedType} vehicle - The vehicle object to map.
 * @returns {Object} An object containing mapped vehicle properties.
 */
function mapVehicleVariables(vehicle: VehicleToBeAddedType) {
  return {
    brand: typeof vehicle?.brand === 'string' ? vehicle?.brand : vehicle?.brand?.id,
    model: typeof vehicle?.model === 'string' ? { title: vehicle?.model } : { id: vehicle?.model?.id },
    model_year: vehicle?.year,
  };
}

/**
 * Generates a string representation of a CustomerVehicle object.
 * @param {CustomerVehicle} vehicle - The CustomerVehicle object to map.
 * @returns {string} A string representation of the vehicle.
 */
export function mapVehicleView(vehicle: CustomerVehicle) {
  const brand = vehicle?.brand?.title ? vehicle?.brand?.title : '';
  const model = vehicle?.model?.title ? vehicle?.model?.title : '';
  const year = vehicle?.year ? vehicle?.year : '';

  // Filter out empty strings and join the values with '-'
  const values = [brand, model, year].filter(Boolean);
  return values.join('-');
}
