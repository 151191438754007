import { AssignVehicleToCartDocument, CustomerCartQuery } from '@/graphql/Cart';
import {
  AddToCartDocument,
  AddToJumpStartOrderDocument,
  BookJumpStartDocument,
  InitiateJumstartDocument,
  JumpstartCartDocument,
  RemoveFromCartDocument,
  RemoveFromJumpStartOrderDocument,
} from '@/graphql/Jumpstart';
import { inject, reactive, ref, toRefs, watch } from '@vue/composition-api';
import { CustomerVehicle, InitiateJumpStartInput } from 'graphql-types.gen';
import { useMutation, useQuery } from 'villus';
import useCookies from './cookies';
import { toNonNullable } from '@/utils/collections';
import { MappedCartItem, mapCartItem } from './cart';
import { AUTH_USER } from '@/utils/provides';
import { useAlerts } from '@/features/alerts';

type JumpstartCartStateType = {
  id: string;
  total: number;
  subtotal: number;
  items: MappedCartItem[];
  products: MappedCartItem[];
  vehicle: Partial<CustomerVehicle>;
};

const jumpstartCartState = reactive<JumpstartCartStateType>({
  id: '',
  items: [],
  products: [],
  total: 0,
  subtotal: 0,
  vehicle: {},
});

type InitiateInputtype = {
  input: InitiateJumpStartInput;
  vehicleUid: string;
};

export function useJumpstart() {
  const isSubmitting = ref(false);

  const { execute } = useMutation(InitiateJumstartDocument);
  const { execute: assignVehicle } = useMutation(AssignVehicleToCartDocument);
  const { setCookie } = useCookies();

  // Initiate jumpstart
  async function initiate({ input, vehicleUid }: InitiateInputtype) {
    try {
      isSubmitting.value = true;

      const { data, error } = await execute({ input });

      if (error) {
        throw new Error(error.message);
      }

      if (data?.response?.cart) {
        const cartId = data?.response?.cart?.id;

        setCookie('jumpstartCart', cartId);

        patchJumpstartCartState(mapJumpstartCartState(data?.response?.cart));

        await assignVehicleAction(cartId, vehicleUid);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    } finally {
      isSubmitting.value = false;
    }
  }

  // Assign vehicle to jumpstart cart
  async function assignVehicleAction(cartId: string, vehicleUid: string) {
    try {
      const { data, error } = await assignVehicle({
        cartId,
        vehicleUid,
      });

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    }
  }

  return {
    isSubmitting,
    initiate,
  };
}

export function useJumpstartCart() {
  const { cookies } = useCookies();

  if (cookies.jumpstartCart) {
    jumpstartCartState.id = cookies.jumpstartCart;
  }

  const { data, isFetching } = useQuery({
    query: JumpstartCartDocument,
    variables: { cartId: jumpstartCartState.id },
    cachePolicy: 'network-only',
    fetchOnMount: true,
  });

  watch(data, (value: any) => {
    patchJumpstartCartState(mapJumpstartCartState(value?.cart));
  });

  return {
    ...toRefs(jumpstartCartState),
    isFetching,
  };
}

export function useJumpstartCartState() {
  return {
    ...toRefs(jumpstartCartState),
  };
}

function patchJumpstartCartState(newState: Partial<typeof jumpstartCartState>) {
  Object.keys(newState).forEach(key => {
    (jumpstartCartState as any)[key] = (newState as any)[key];
  });
}

function mapJumpstartCartState(cart: CustomerCartQuery['cart'] | null): typeof jumpstartCartState {
  if (!cart) {
    return jumpstartCartState;
  }

  // All items (jumpstart service && products if were added to jumpstart cart)
  const items = toNonNullable(cart.items).map?.(mapCartItem);

  return {
    id: cart.id,
    total: cart.prices?.grand_total?.value ?? 0,
    subtotal: cart.prices?.subtotal_including_tax?.value ?? 0,
    items,
    // Products without jumpstart service
    products: items?.filter(item => item?.sku !== 'jump-start-me'),
    vehicle: cart.vehicle as CustomerVehicle,
  };
}

export function useAddJumpstartCartItem() {
  const { execute, isFetching: isAdding } = useMutation(AddToCartDocument);

  async function addItem(sku: string) {
    if (jumpstartCartState.products.length) return;

    const { data, error } = await execute({
      input: {
        cart_id: jumpstartCartState.id,
        cart_items: [
          {
            data: { quantity: 1, sku },
          },
        ],
      },
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data?.response) {
      patchJumpstartCartState(mapJumpstartCartState(data.response.cart));
    }
  }

  return {
    addItem,
    isAdding,
  };
}

export function useRemoveJumpstartCartItem() {
  const { execute, isFetching: isRemoving } = useMutation(RemoveFromCartDocument);

  async function removeItem(id: string) {
    const { data, error } = await execute({
      input: {
        cart_id: jumpstartCartState.id,
        cart_item_uid: id,
      },
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data?.response) {
      patchJumpstartCartState(mapJumpstartCartState(data.response.cart));
    }
  }

  return {
    removeItem,
    isRemoving,
  };
}

// On book jumpstart hook
export function useBookJumpstart() {
  // Admin value
  const user = inject(AUTH_USER);

  const { execute, isFetching } = useMutation(BookJumpStartDocument);

  async function bookJumpstart(cartId: string) {
    try {
      const { data, error } = await execute({ cartId, adminEmail: user?.value?.email });

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return {
    bookJumpstart,
    isFetching,
  };
}

/**
 * Custom hook for adding an item to a Jumpstart order.
 *
 * @function useAddToJumpstartOrder
 * @returns {Object} The hook's return object containing the addItem function and isAdding state.
 */
export function useAddToJumpstartOrder() {
  const { execute, isFetching: isAdding } = useMutation(AddToJumpStartOrderDocument);
  const { success, error: errorToast } = useAlerts();

  async function addItem(sku: string, orderNumber: string) {
    try {
      const { error } = await execute({
        input: {
          sku,
          orderNumber,
        },
      });

      if (error) {
        errorToast(error.message);
        throw new Error(error.message);
      }

      success('تم إضافه البطارية بنجاح.');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return {
    addItem,
    isAdding,
  };
}

/**
 * Custom hook for removing an item from a Jumpstart order.
 *
 * @function useRemoveFromJumpstartOrder
 * @returns {Object} The hook's return object containing the removeItem function and isRemoving state.
 */
export function useRemoveFromJumpstartOrder() {
  const { execute, isFetching: isRemoving } = useMutation(RemoveFromJumpStartOrderDocument);
  const { success, error: errorToast } = useAlerts();

  async function removeItem(id: string, orderNumber: string) {
    try {
      const { error } = await execute({
        input: {
          items: [
            {
              itemId: id,
              qty: 1,
            },
          ],
          orderNumber,
        },
      });

      if (error) {
        errorToast(error.message);
        throw new Error(error.message);
      }

      success('تم حذف البطارية بنجاح');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return {
    removeItem,
    isRemoving,
  };
}
