import { StoreConfigDocument } from '@/graphql/StoreConfig';
import { useQuery } from 'villus';

export function useConfig() {
  const { execute } = useQuery({ query: StoreConfigDocument, fetchOnMount: false });

  async function getConfig() {
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    return {
      maxCapacity: data?.storeConfig?.maxCapacity || 10,
      maxSlots: data?.storeConfig?.maxSlots || 14,
      slotInMinutes: data?.storeConfig?.slotDuration || 15,
    };
  }

  return {
    getConfig,
  };
}
