<template>
  <ValidationProvider v-slot="{ errors, required }" :name="label" :vid="id" :rules="rules" slim>
    <div :class="{ 'has-errors': !!errors[0] || errorMessage }" class="DatePickerInput">
      <div
        :class="{ 'has-errors': !!errors[0] || errorMessage, [controlClass]: true }"
        class="DatePickerInput__Control"
      >
        <label v-if="label" :for="id" class="text-base text-gray-300"> {{ label }} {{ required ? '*' : '' }} </label>

        <div class="relative pr-10">
          <Datepicker
            ref="datePicker"
            v-model="innerValue"
            :placeholder="placeholder"
            :language="language"
            :months="1"
            :highlighted="highlighted"
            :disabled-dates="disabledDates"
            :disabled="disabled"
            @focusin.native="focused = true"
            @closed="focused = true"
          />

          <span class="text-gray-400">
            <SvgIcon name="calendar" class="fill-current w-5 h-5 absolute calendar-icon" />
          </span>
        </div>

        <span class="mt-1 text-red-100 text-xs">{{ errors[0] || errorMessage }}</span>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { ar } from 'vuejs-datepicker/dist/locale';
import SvgIcon from '@/components/SvgIcon.vue';

export default {
  name: 'DatePickerInput',
  components: {
    ValidationProvider,
    Datepicker: () => import('vuejs-datepicker'),
    SvgIcon,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    value: {
      type: Date,
      default: null,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    controlClass: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    highlighted: {
      type: Object,
      default: () => ({}),
    },
    disabledDates: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      innerValue: '',
      focused: false,
      language: ar,
    };
  },

  watch: {
    innerValue(value) {
      if (value === this.value) {
        return;
      }

      this.$emit('input', value);
    },
    value: {
      handler(value) {
        this.innerValue = value;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="postcss">
/* purgecss start ignore */
.DatePickerInput {
  &__Control {
    @apply flex flex-col items-center relative border-b;

    .vdp-datepicker {
      @apply w-full;
      input {
        @apply h-9 border-0 border-b border-gray-400 w-full outline-none;
      }

      .day__month_btn {
        @apply bg-gray-100;
        &:hover {
          @apply bg-gray-200;
        }
      }

      &__calendar {
        @apply border-gray-500 mt-1;
        .cell:not(.blank):not(.disabled).day:hover,
        .cell:not(.blank):not(.disabled).month:hover,
        .cell:not(.blank):not(.disabled).year:hover {
          @apply border-red-100;
        }

        .cell {
          &.highlighted {
            @apply bg-gray-400;
          }
          &.selected,
          &.selected:hover {
            @apply bg-red-100 text-white;
          }
        }
      }
    }
    &.has-errors {
      label {
        @apply text-red-100;
      }

      .vdp-datepicker {
        input {
          @apply border-red-100;
        }
      }
    }
  }
}
/* purgecss end ignore */

.calendar-icon {
  @apply top-2 right-2;
}
</style>
