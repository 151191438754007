import { DirectiveFunction } from 'vue';
import { isPlainObject } from 'lodash-es';

export const load: DirectiveFunction = (el, { value }) => {
  if (isPlainObject(value)) {
    const { isLoading, isCentered } = value;
    el.classList.toggle('is-loading', isLoading);
    el.classList.toggle('centered', isLoading && isCentered);
  } else if (typeof value === 'boolean') {
    el.classList.toggle('is-loading', value);
  }
};
