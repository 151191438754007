export interface SelectOption {
  label: string;
  value: string | number;
}

/**
 * Populate the options for the selector used for any service
 * to set the cars capacity this store has for this specific service.
 * @param { number } maxCapacity - maximum number of cars allowed for any service across all stores
 * @returns { Array } An array of objects that represent the options with label and value
 */
export function populateCapacityOptions(maxCapacity: number): Array<SelectOption> {
  const options: SelectOption[] = [];
  for (let i = 0; i < maxCapacity; i++) {
    options.push({
      label: `${i + 1} سيارات`,
      value: i + 1,
    });
  }
  return options;
}

/**
 * Populate the options for the selector used for any service
 * to set the amount of time this service takes; 15mins, 30mins, 60mins, etc.
 * @param { number } maxSlots - maximum number of slots allowed for any service across all stores
 * @param { number } slotDuration - the duration of each slot in minutes
 * @returns { Array } An array of objects that represent the options with label and value
 */
export function populateSlotOptions(maxSlots: number, slotDuration: number): Array<SelectOption> {
  const options: SelectOption[] = [];
  for (let i = 0; i < maxSlots; i++) {
    options.push({
      label: `${(i + 1) * slotDuration} دقيقة`,
      value: (i + 1) * slotDuration,
    });
  }
  return options;
}
