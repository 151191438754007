import Vue from 'vue';
import { ValidationProvider, extend, configure, localize } from 'vee-validate';
import {
  required,
  email,
  min,
  max,
  max_value,
  confirmed,
  alpha_spaces,
  min_value,
  digits,
  alpha_dash,
} from 'vee-validate/dist/rules';

extend('required', required);
extend('email', email);
extend('min', min);
extend('max', max);
extend('confirmed', confirmed);
extend('max_value', max_value);
extend('min_value', min_value);
extend('alpha_spaces', alpha_spaces);
extend('alpha_dash', alpha_dash);
extend('digits', digits);
extend('phone', value => {
  const sanitized = value.replace(/-/g, '');

  return sanitized.length === 11 && sanitized.startsWith('01');
});
extend('password', value => {
  // Password must contain at least a letter, a number and a special character, and min length of 8
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(value);
});

configure({
  useConstraintAttrs: false,
});

localize('ar');

localize({
  ar: {
    fields: {
      phone: {
        phone: 'رقم الهاتف غير صالح',
      },
      password: {
        min: 'يجب ان تتكون كلمة السر على ٨ أحرف على الأقل',
      },
      email: {
        email: 'البريد الإلكتروني غير صالح',
      },
      username: {
        alpha_dash: 'يجب أن يحتوي اسم المستخدم على حروف وأرقام وعلامات فقط',
      },
      comment: {
        max: 'لا يمكن أن يزيد التعليق عن ٢٠٠ حرف',
      },
    },
    messages: {
      required: 'هذه الخانة إجبارية',
      alpha_spaces: 'يجب ان يتكون الاسم من حروف فقط ',
      password:
        'يجب أن تحتوي كلمة المرور على الأقل على: حرف واحد كبير، وحرف صغير واحد، ورقم واحد، وحرف خاص واحد (على سبيل المثال، . _ & ? إلخ)',
    },
  },
});

Vue.component('ValidationProvider', ValidationProvider);
