import * as Types from '../../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StoreConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StoreConfigQuery = { __typename?: 'Query', storeConfig?: { __typename?: 'StoreConfig', maxCapacity?: number | null, maxSlots?: number | null, slotDuration?: number | null } | null };

export declare const StoreConfig: import("graphql").DocumentNode;

export const StoreConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"StoreConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"storeConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"maxCapacity"},"name":{"kind":"Name","value":"bookable_services_max_capacity"}},{"kind":"Field","alias":{"kind":"Name","value":"maxSlots"},"name":{"kind":"Name","value":"bookable_services_max_slots"}},{"kind":"Field","alias":{"kind":"Name","value":"slotDuration"},"name":{"kind":"Name","value":"bookable_services_slot_duration"}}]}}]}}]} as unknown as DocumentNode<StoreConfigQuery, StoreConfigQueryVariables>;